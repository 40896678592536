//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      state: {
        domain: '',
        signature: '',
        securityCode: '',
        unsubcribeHeader: false,
        unsubcribeLink: '',
      },
    };
  },

  computed: {
    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    value(val) {
      if (val) {
        Object.keys(this.state).forEach((key) => {
          this.state[key] = this.data[key];
        });
      }
    },
  },
};
