//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import UserSelect from '~/components/UserSelect.vue';

export default {
  components: {
    UserSelect,
  },

  props: {
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      tab: '',
      userIdList: [],
      emailTypeList: 57,
      userTagList: [],
      emailTypeListItems: [
        {
          id: 57,
          caption: 'News',
        },
        {
          id: 58,
          caption: 'Marketing',
        },
      ],
      isForcedSend: false,
      previewTemplate: {
        state: false,
        body: '',
      },
      localesData: [],
    };
  },

  computed: {
    ...mapState('users', ['userTagsList']),
    ...mapGetters('common', ['exchangeLanguageList']),

    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    disabledUsers() {
      return this.userTagList.length > 0;
    },
    disabledTagList() {
      return this.userIdList.length > 0;
    },
  },

  mounted() {
    this.renderLocalesData();
  },

  methods: {
    ...mapActions('common', [
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
      'setGeneralProgress',
    ]),
    ...mapActions('settingsEmail', {
      sendEmailToUsersAction: 'sendEmailToUsers',
    }),
    showPreviewTemplate(body) {
      this.previewTemplate.state = true;
      this.previewTemplate.body = body;
    },

    async sendEmail() {
      const {
        localesData,
        userIdList,
        userTagList,
        isForcedSend,
        emailTypeList,
      } = this;

      const isEmptyLocales = Object.entries(localesData).map((item) => {
        let text = '';
        if (item[1].message.length === 0) text += this.$t('In locale $item - Body is empty', { item: item[0] });
        if (item[1].subject.length === 0) text += this.$t('In locale $item - Subject is empty', { item: item[0] });

        return text;
      });

      this.confirmAction({
        title: 'Are you sure want to send email to users?',
        text: `${isEmptyLocales.join('')}`,
        hasCancelButton: true,
        callback: async () => {
          try {
            this.setGeneralProgress(true);
            await this.sendEmailToUsersAction({
              userIds: userIdList,
              tagIds: userTagList,
              notificationType: emailTypeList,
              isForcedSend,
              messages: localesData,
            });
            this.setGeneralProgress(false);
            this.setSuccessNotification('Email successfully sent');
            this.closeSendEmailModal();
          } catch (error) {
            this.setErrorNotification(error);
          }
        },
      });
    },

    async applySendEmail() {
      const {
        localesData,
        userIdList,
        userTagList,
        isForcedSend,
        emailTypeList,
      } = this;

      try {
        await this.sendEmailToUsersAction({
          userIds: userIdList,
          tagIds: userTagList,
          notificationType: emailTypeList,
          isForcedSend,
          messages: localesData,
        });
      } catch (error) {
        this.setErrorNotification(error);
      }
    },

    closeSendEmailModal() {
      this.renderLocalesData();
      this.userIdList = [];
      this.userTagList = [];
      this.isForcedSend = false;
      this.emailTypeList = 57;
      this.$emit('close', false);
    },

    renderLocalesData() {
      const langList = this.exchangeLanguageList.map((item) => {
        return [item.id, {
          subject: '',
          message: '',
        }];
      });
      this.localesData = Object.fromEntries(langList);
    },
  },
};
