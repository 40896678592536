//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapGetters } from 'vuex';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        emailBodyType: 0,
        language: '',
      },
      constant,
    };
  },

  computed: {
    ...mapState('settingsEmail', ['filterLists']),
    ...mapGetters('common', ['exchangeLanguageList']),
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    // this.setFilters({});
    const emailBodyTypeId = constant.notifications.EMAIL_BODY_TYPE_LIST[0].id;
    const languageId = this.exchangeLanguageList[0].id;
    this.filters = {
      emailBodyType: emailBodyTypeId,
      language: languageId,
    };
  },

  methods: {
    ...mapActions('settingsEmail', ['setFilters']),
  },
};
